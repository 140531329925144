body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}